import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import Hero from '../components/hero';

function EyeCare({}) {
  return (
    <Layout>
      <Hero title="Eye Care" subtitle="Optimizing Vision & Maintaining Eye Health" color="light" />
      <section className="section content">
        <div className="container">
          <h3 className="title heading is-5 ">Routine Eye Care</h3>
          <p>
            Routine eye exams are important in preserving your vision and overall eye health.
            Although your vision may not have changed, it is important to have a doctor examine the
            health of your eyes to ensure your vision remains strong. Depending on many factors,
            routine eye exams should be done every 1-2 years.
          </p>
          <h3 className="title heading is-5">Medical Services</h3>
          <p>
            We are licensed to practice at the highest level of optometric care including the
            detection and treatment of various ocular diseases and complications. In addition, any
            situations that require speciality care are addressed by our extensive speciality
            referral network.
          </p>
          <h3 className="title heading is-5">Pediatrics</h3>
          <p>
            All children should have an eye exam by 5 years old regardless of school and primary
            care vision screenings. Although screenings are a good start, many visual problems can
            go undetected at screenings that can interfere with reading and learning.
          </p>
          <h3 className="title heading is-5">Sports Vision</h3>
          <p>
            We recognize and address the special visual needs of our athlete patients. This can
            include specific visual evaluations, specific design sports frames/lenses, and contact
            lenses.
          </p>
          <h3 className="title heading is-5">Computer &amp; Task-Specific Vision</h3>
          <p>
            We all want to see our world effortlessly, but that cannot always be achieved with basic
            glasses or contact lenses. The doctors at Boroyan Eye Associates provide special visual
            testing and lens designs to make you more efficient and comfortable throughout your day.
          </p>
          <div className="notification is-warning">
            <strong>
              If this is an ocular emergency, please call us at (978) 256-8501 and we will be able
              to assist you. If the office is closed, please call 911 or go to an emergency room.
            </strong>
          </div>
        </div>
      </section>
    </Layout>
  );
}

EyeCare.propTypes = {};

export default EyeCare;

export const query = graphql`
  query {
    andrea: file(relativePath: { eq: "andrea-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    henry: file(relativePath: { eq: "henry-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    doreen: file(relativePath: { eq: "doreen-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
